<template>
  <div id="project-timeline" class="light-gradient" :style="`background-image:url('${require('@/assets/images/canal-district/project-timeline.jpg')}')`">
        <div class='add-padding'>
          <div class='limit-width'>
            <PageTitle dark title="Project Timeline"/>
            <div class='scroll-small-window'>
              <img src="@/assets/images/canal-district/project-timeline.svg" alt="" style="min-width: 900px;">
            </div>
          </div>
        </div>
    </div>
</template>

<script>
import PageTitle from '@/components/PageTitle';
export default {
    name:'ProjectTimeline',
    components: {      
      PageTitle,
  },
}
</script>

<style lang="scss" scoped>
  #project-timeline {
    background-size: cover;
    text-align: center;
    position: relative;
    padding-top:75px;
    padding-bottom:50px;
    > div {
        position: relative;
    }
    img {
      width:100%;        
    }
}
</style>