<template>
  <div>
    <transition name="slide-fade" mode="out-in" appear>
      <SubNav :items="activePages"/>
    </transition>
    <div class='main'>
      <div v-for="page in activePages" :key="page.id">
        <Waypoint @change="onChange" :hash="page.hash" :id="page.id"/>
        <Component :is="page.component" :key="page.id" />        
      </div>      
      <Footer />
    </div>
  </div>
</template>

<script>
/*
Add Waypoint to update hash based on scroll
but trigger page tracking on watch of url change; that way you don't call tracking on every page that is scrolled past
*/
import axios from 'axios';
import { shallowRef } from 'vue'
import { Waypoint } from 'vue-waypoint';

import Footer from '@/components/Footer';
import SubNav from '@/components/SubNav';

import OurVision from '@/pages/OurVision';
import Community from '@/pages/Community';
import Connecting from '@/pages/Connecting';
import ProjectTimeline from '@/pages/ProjectTimeline';

const ourVision = shallowRef(OurVision)
const community = shallowRef(Community)
const connecting = shallowRef(Connecting)
const projectTimeline = shallowRef(ProjectTimeline)
export default {
  name: 'CanalDistrict',
  components: {
      Footer,
      SubNav, 
      Waypoint     
  },
  setup() {    
  },
  methods : {
    onChange(waypointState) {
      if(this.$store.state.linkScrolling) {
        return;
      }
      
      if(waypointState.going === "IN") {
        if(history.pushState) {
          console.log(waypointState)
          //history.pushState(null, null, `#${waypointState.el.getAttribute('hash')}`);
        }
        //window.location.hash = 
      }
    }
  },
  mounted() {
      if(this.$store.state.preview) {
        return;
      }
      const post = {
        method:"page",
        purl:this.$store.state.purl,
        sessionDbId:this.$store.state.sessionDbId,
        section:'2'
      }
      axios.post(this.$store.state.baseUrl + "EpitchLiteTracking.php", post);     
  },
  computed: {
    activePages() {
        let serverPages = this.$store.state.pages.filter(serverPage => {      
            return this.pages.findIndex(page => serverPage.id === page.id) === -1 ? false : true;                
        });
        let pages = [];
        serverPages.forEach(element => {
          pages.push({...element, ...this.pages[this.pages.findIndex(page => element.id === page.id)]});
          
        });
        return pages;
    }
  },
  data: () => ({   
      pages : [
          {
            id:"13",
            path : 'canal-district',
            hash : 'our-vision',
            name : 'Our Vision',
            component:ourVision,
          },
          {
            id:"14",
            path : 'canal-district',
            hash : 'connecting',
            name : 'Connecting Community, Business & Opportunities',
            component:community,
          },
          {
            id:"15",
            path : 'canal-district',
            hash : 'community',
            name : 'Community Gathering Spot',
            component:connecting,
          },
          {
            id:"16",
            path : 'canal-district',
            hash : 'project-timeline',
            name : 'Project Timeline',
            component:projectTimeline,
          },
      ]      
  }),
}
</script>