<template>
    <div id="our-vision" class="top">  
      <Gallery :images="[
      {file:'canal-district/top/CD-Hero-Image1.jpg', title: ''},
      {file:'canal-district/top/CD-Hero-Image2.jpg', title: ''},
      ]"/>
      <transition name="slide-fade" mode="out-in" appear>
        <div class='limit-width'>
          <h1 class="white">Making this dream into a reality</h1>
          <h4 class="white">The city of Savannah had been working to realize their vision for a new arena for years. OVG’s involvement and investment are finally bringing it to life.</h4>      
        </div> 
      </transition>
    </div>
</template>

<script>
import Gallery from '@/components/Gallery';
export default {
    name:'OurVision',
    components: {      
      Gallery,
  },
}
</script>
<style lang="scss" scoped>
.top {    
    .cta {
      margin-top: 20px;
    }
  }  
</style>