<template>
  <div id="community" class="dark-gradient">
    <Gallery :images="[
    {file:'canal-district/community/CD-Community-GalleryImage1.jpg', title: ''},
    {file:'canal-district/community/CD-Community-GalleryImage2.jpg', title: ''},
    {file:'canal-district/community/CD-Community-GalleryImage3.jpg', title: ''},
    {file:'canal-district/community/CD-Community-GalleryImage4.jpg', title: ''},
    ]"/>
    <div class='add-padding'>
    <div class='limit-width'>
        <PageTitle title="Bringing The<br/>Community Together"/>
        <div>
        <h4 class="white">Providing a place for the community to gather all year round.</h4>
        <ul class="white body">
            <li>Create a more memorable outdoor experience before, after and outside of events in the Spring Field Canal</li>
            <li>An all-day restaurant</li>
            <li>Create a vibrant civic plaza at the main entry, for use on non-event days (i.e., Farmers Market)</li>
            <li>The only arena that allows you to arrive for an event by boat</li>
        </ul>
        </div>
    </div>
    </div>
</div>
</template>

<script>
import PageTitle from '@/components/PageTitle';
import Gallery from '@/components/Gallery';
export default {
    name:'Community',
    components: {      
      PageTitle,
      Gallery
  },
}
</script>

<style lang="scss" scoped>
  #community {
    min-height:calc(100vh - 140px);
    overflow: auto;
    h4, ul {
      position: relative;
    }
    ul {
      padding:0;
    }
    h4 {
      max-width: 525px;
    }
  }  
</style>