<template>
    <div id="connecting" class="light-gradient" :style="`background-image:url('${require('@/assets/images/canal-district/connecting.jpg')}')`">
      <div class='add-padding'>
        <div class='limit-width'>
          <PageTitle title="Enriching the Quality of<br/>Life of Savannah Residents" dark/>
          <div>
            <h4>Our vision is to continue progressing Savannah while ensuring all aspects of the development benefit the community and facilitate economic growth and cultural progression.</h4>
            <div class='cards'><Card v-for="(card, id) in cards" :key="id" :card="card"/></div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import PageTitle from '@/components/PageTitle';
import Card from '@/components/Card';
export default {
    name:'Connecting',
    components: {      
      PageTitle,
      Card
  },
    data: () => ({   
      cards: [
        {          
          title:'Connect the  Community',
          image:'canal-district/cards/community.jpg',
          description:'Develop infrastructure to bridge the gap between downtown Savannah and the city’s historically impoverished western neighborhoods.'
        },
        {
          title:'Business Opportunities',
          image:'canal-district/cards/business-opportunities.jpg',
          description:'Providing business opportunities for area residents. Coordinating with local economic development organizations on business retention programs for existing local businesses.'
        },
        {
          title:'Historical Preservation',
          image:'canal-district/cards/historical-preservation.jpg',
          description:'Preserving historic Canal District landmarks like the Water Works building.'
        },
        {
          title:'Job Creation',
          image:'canal-district/cards/job-creation.jpg',
          description:'Ensure that local labor is employed in the project’s construction'
        },
        {
          title:'Diversity & Inclusion',
          image:'canal-district/cards/diversity.jpg',
          description:'75% of operating expenses are going to local minority-owned companies (include female-owned businesses)'
        },
      ] 
  }),
}
</script>
<style lang="scss" scoped>
  #connecting {
    overflow: auto;
    background-size: cover;
    h4, ul {
      position: relative;
    }
    ul {
      padding:0;
    }
    h4 {
      max-width: 525px;
    }
    
    .cards {
      margin-top:35px;
      margin-bottom:85px;
      display: grid;      
      grid-template-columns: 1;
      gap:15px;
      .card {
        width:100%;
      }
    }
  }
  @media (min-width:625px) {
    #connecting .cards {
      grid-template-columns: repeat(auto-fit, 205px);      
    }    
  }
</style>